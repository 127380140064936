@import "nProgress/nProgress";
@import "config";

html {
  min-height: 100vh;
  font-size: rem(16px);
}

/* -- HIDE LOGO --*/
header {
  width: 100% !important;
  .logo {
    &.mobile {
      filter: brightness(0) invert(1);
    }
    img {
      width: 135px;
      height: 43px;
    }
  }

}

.MuiDrawer-docked {
  position: relative;
  z-index: 1;
}

body {
  background: #FFF !important;
  min-height: inherit;
  scroll-behavior: smooth;
}

pre {
  font-size: rem(10px);
}

#__next {
  min-height: inherit
}

.app {
  display: flex
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: .8 !important;
}

::-moz-placeholder { /* Firefox 19+ */
  opacity: .8 !important;
}

:-ms-input-placeholder { /* IE 10+ */
  opacity: .8 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* -- HELPERS -- */

.pulled-right {
  float: right
}

.padding-less {
  padding: 0
}

.error {
  color: $red
}

.xhr {
  position: relative;
  transition: opacity .3s;
  &.busy {
    pointer-events: none;
    opacity: .3
  }
}

/* -- LAYOUT -- */

.left {
  padding: 105px 26px 26px 26px;
  .logo {
    max-width: 180px;
  }

  .searchbar {
    svg {
      color: $ternary-color
    }
    padding-bottom: 20px;
  }
  ul.menu {
    li {
      padding: 8px 0px;
      > a {
        font-size: rem(24px);
        text-decoration: none;
        &:hover, &.active {
          color: $ternary-color;
          font-weight: $bold;
          text-decoration: none;
        }
      }
    }

  }
  .separator {
    padding: 35px 0px;
  }
  &.xs {
    padding: 26px;
    .separator {
      padding: 15px 0px;
    }
    .qrcode {
      color: $primary-color;
      font-weight: $medium;
    }
  }
}

.header {
  .language {
    padding-right: 8px;
  }
  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      border: none;
      padding-left: 8px;
      &:hover {
        background: none;
        color: $ternary-color;
        text-decoration: underline
      }
    }
  }
}

.MuiBreadcrumbs-ol {
  .MuiBreadcrumbs-separator {
    svg {
      font-size: rem(16px)
    }
  }
  li:last-child {
    a {
      font-weight: $bold;
      color: #808080
    }

  }
  a {
    font-size: rem(14px);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.MuiPagination-root {
  .Mui-selected {
    color: $ternary-color
  }
}

.MuiButton-root {
  &.snow {
    border: 1px solid #CCC;
    padding: 6px 14px;
    color: $ternary-color;
    font-weight: $medium;
    letter-spacing: rem(1.25px);
    font-size: rem(14px);
    &:hover {
      background-color: $ternary-color;
      color: white;
      text-decoration: none;
    }
  }
  &.orange {
    border: 1px solid $grey;
    padding: 12px 44px;
    color: $secondary-color;
    letter-spacing: rem(1.25px);
    font-size: rem(20px);
    line-height: rem(20px);
    border-radius: 56px;
    &:hover {
      background-color: $secondary-color;
      color: white;
    }
  }
}

.lorem {
  @include lorem;
  padding-bottom: 20px;
}

@keyframes rowFadeOut {
  from {
    opacity: 1;
    background: #ffb2ae;
  }
  to {
    opacity: 0;
  }
}

@keyframes rowAppear {
  from {
    transform: scaleY(0);
    background: rgba(144, 238, 144, 0.3)
  }
  to {
    transform: scaleY(1);
  }
}

.__aligned_center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}


.__pulled_right {
  margin-left: auto;
}


.menu-icon {
  padding: 2px 5px 2px 0
}

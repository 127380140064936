@import "styles/config.scss";

.login :global {

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }

  form {
    padding: 50px 0;
    > div {
      padding-bottom: 15px;
    }
  }

  .buttons {
    padding-top: 35px;
    .login {
      padding-bottom: 10px;
    }
  }
}
